import React from 'react';

function Portfolio() {
  return (
    <article className="portfolio active" data-page="portfolio">
      {
        <p>Nội dung của phần Portfolio ở đây</p>
        }
    </article>
  );
}

export default Portfolio;
