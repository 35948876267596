import React from 'react';

function About() {
  return (
    <article className="about active" data-page="about">
      {
        <p>Nội dung của phần About ở đây</p>
        }
    </article>
  );
}

export default About;
