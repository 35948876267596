import React, { useState, useRef, useEffect } from 'react';
import About from './About';
import Resume from './Resume';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Game from './Game';
import './App.css';

function App() {
  const [activeTab, setActiveTab] = useState('about');
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('mode') === 'dark'
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem('status') !== 'close'
  );

  const bodyRef = useRef(null);
  const modeToggleRef = useRef(null);
  const sidebarRef = useRef(null);
  const sidebarToggleRef = useRef(null);

  useEffect(() => {
    const handleModeToggle = () => {
      bodyRef.current.classList.toggle('dark');
      setIsDarkMode(!isDarkMode);
      localStorage.setItem('mode', isDarkMode ? 'dark' : 'light');
    };

    const handleSidebarToggle = () => {
      sidebarRef.current.classList.toggle('close');
      setIsSidebarOpen(!isSidebarOpen);
      localStorage.setItem('status', isSidebarOpen ? 'open' : 'close');
    };

    // Attach event listeners after elements are rendered
    if (bodyRef.current && modeToggleRef.current) {
      modeToggleRef.current.addEventListener('click', handleModeToggle);
    }

    if (sidebarRef.current && sidebarToggleRef.current) {
      sidebarToggleRef.current.addEventListener('click', handleSidebarToggle);
    }

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      modeToggleRef.current?.removeEventListener('click', handleModeToggle);
      sidebarToggleRef.current?.removeEventListener('click', handleSidebarToggle);
    };
  }, []);

  return (
    <body className={`app ${isDarkMode ? '' : 'dark'}`}>
      <nav
        className={`sidebar ${isSidebarOpen ? '' : 'close'}`}
        ref={sidebarRef}
      >
      <div class="logo-name">
        <div class="logo-image">
          <img src={require("./Images/logo.png")} alt="" />
        </div>

        <span style={{'white-space': 'nowrap'}} class="logo_name">Công cụ</span>
      </div>

      <div class="menu-items">
        <ul class="nav-links">
          <li>
            <a href="#" onClick={() => setActiveTab('about')} className={activeTab === 'about' ? 'active' : ''}>
              <i class="uil uil-estate"></i>
              <span class="link-name">Quản lý</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setActiveTab('resume')} className={activeTab === 'resume' ? 'active' : ''}>
              <i class="uil uil uil-image-edit"></i>
              <span class="link-name">Chỉnh ảnh</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setActiveTab('portfolio')} className={activeTab === 'portfolio' ? 'active' : ''}>
              <i class="uil uil-cloud"></i>
              <span class="link-name">Thời tiết</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setActiveTab('contact')} className={activeTab === 'contact' ? 'active' : ''}>
              <i class="uil uil-qrcode-scan"></i>
              <span class="link-name">Mã QR</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setActiveTab('contact')} className={activeTab === 'contact' ? 'active' : ''}>
              <i class="uil uil-text-fields"></i>
              <span class="link-name">Tốc độ gõ</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={() => setActiveTab('game')} className={activeTab === 'game' ? 'active' : ''}>
              <i class="uil uil-notes"></i>
              <span class="link-name">Trò chơi</span>
            </a>
          </li>
        </ul>

        <ul className="logout-mode">
          <li>
            <a href="#">
              <i className="uil uil-signout"></i>
              <span className="link-name">Thoát</span>
            </a>
          </li>

          <li className="mode"
              onClick={() => {
                setIsDarkMode(!isDarkMode);
              }}>
            <a href="#">
              <i className="uil uil-moon"></i>
              <span className="link-name">Chế độ tối</span>
            </a>

            <div className="mode-toggle" ref={modeToggleRef}>
              <span className="switch"></span>
            </div>
          </li>
        </ul>
        </div>
      </nav>

      <section className="dashboard">
        <div className="top">
          <i
            className="uil uil-bars sidebar-toggle"
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          ></i>

          <div className="search-box">
            <i className="uil uil-search"></i>
            <input type="text" placeholder="Tìm kiếm..." />
          </div>

          <a>
            <img className="show-modal" src={require("./Images/profile.jpg")} alt="" />
          </a>
        </div>

        <div className="dash-content">
          {activeTab === 'about' && <About />}
          {activeTab === 'resume' && <Resume />}
          {activeTab === 'portfolio' && <Portfolio />}
          {activeTab === 'contact' && <Contact />}
          {activeTab === 'game' && <Game />}
        </div>
      </section>
    </body>
  );
}

export default App;