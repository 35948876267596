import React from 'react';

function Contact() {
  return (
    <article className="contact active" data-page="contact">
      {
      <p>Nội dung của phần Contact ở đây</p>
      }
    </article>
  );
}

export default Contact;
