import React from 'react';

function Resume() {
  return (
    <article className="resume active" data-page="resume">
      {
        <p>Nội dung của phần Resume ở đây</p>
    }
    </article>
  );
}

export default Resume;
