import React from 'react';
import "./Css/Game.css"


function Game() {
    const products = [
        { id:1, name: "Tetris", desc: "Tetris", img: "tetris", ref: "tetris"},
        { id:2, name: "Connect 4", desc: "Connect 4", img: "connect4", ref: "connect4"},
        { id:3, name: "Sudoku", desc: "Sudoku", img: "sudoku", ref: "sudoku"},
        { id:4, name: "Pink Snow", desc: "Pink Snow", img: "pinkSnow", ref: "pinkSnow"},
        { id:5, name: "Pink Ice", desc: "Pink Ice", img: "pinkIce", ref: "pinkIce"},
        { id:6, name: "Puyo Puyo", desc: "Puyo Puyo", img: "puyo", ref: "puyo"},
        { id:7, name: "Tron", desc: "Tron", img: "tron", ref: "tron"},
        { id:8, name: "2048", desc: "2048", img: "2048", ref: "2048"},
    ];

  return (
    <article className="game active" data-page="game">
      {
        <div class="container">
            <div class="title">
                <h1>Trò chơi</h1>
                {/* <br/><p>Tổng hợp các game được chia sẻ miễn phí trên Internet</p> */}
            </div>
        
            <div class="list">

                {products.map((products) => (
                    <div className="responsive" key={products.id}>  {/* Added key prop */}
                        <div className="gallery">
                        <a href={`./${products.ref}.html`}>
                            <img src={require(`./Images/${products.img}.jpg`)} alt={products.name} />
                        </a>
                        <div className="desc">{products.name}</div>
                        </div>
                    </div>
                ))}

            <div class="clearfix"></div>
        </div>
        </div>
      }
    </article>
  );
}

export default Game;
